import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DialogModule } from "@lcs/dialog/dialog.module";
import { LinkModule } from "@lcs/link/link.module";
import { OverlayPanelsModule } from "@lcs/overlay-panel/overlay-panels.module";
import { SidebarMenuService } from "@lcs/sidebar-menu/sidebar-menu.service";

import { OWASessionService } from "../session/owa-session.service";
import { SystemWebPreferencesSessionService } from "../session/systemwebpreference-session.service";
import { SwitchAccountComponent } from "../switch-account/switch-account.component";
import { WebChatModule } from "../web-chat/web-chat.module";
import { AccountContextMenuComponent } from "./account-context-menu/account-context-menu.component";
import { AccountMenuComponent } from "./account-menu/account-menu.component";
import { MenuComponent } from "./menu/menu.component";
import { MenuService } from "./menu/menu.service";
import { SiteHeaderComponent } from "./site-header/site-header.component";
import { SwitchAccountLoadingComponent } from "./switch-account-loading/switch-account-loading.component";
import { TopBarComponent } from "./top-bar.component";
import { LocationModule } from "../authentication/rm-location/rm-location.module";

@NgModule({
   imports: [CommonModule, RouterModule, OverlayPanelsModule, DialogModule, LinkModule, WebChatModule, LocationModule],
   declarations: [
      SiteHeaderComponent,
      TopBarComponent,
      MenuComponent,
      AccountMenuComponent,
      AccountContextMenuComponent,
      SwitchAccountComponent,
      SwitchAccountLoadingComponent,
   ],
   providers: [MenuService, OWASessionService, SystemWebPreferencesSessionService, SidebarMenuService],
   exports: [TopBarComponent],
})
export class TopBarModule {}
