import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { filter, Subject, takeUntil } from "rxjs";

import { TopBarService } from "./top-bar.service";

import { SystemWebPreferencesService } from "projects/libraries/owa-gateway-sdk/src/lib/services/systemwebpreferences.service";
import { LocationComponent } from "../authentication/rm-location/rm-location.component";
import { LocationStrategy } from "@angular/common";

@Component({
   selector: "owa-top-bar",
   templateUrl: "./top-bar.component.html",
   styleUrls: ["./top-bar.component.css"],
})
export class TopBarComponent implements OnInit {
   isVisible: boolean;

   overrideDefaultHeader: boolean;
   customHeaderColor: string = "#F2F5FA";
   siteHeaderPath: string;
   loginHeaderAlignment: string;
   isLoginPage: boolean;

   public locationId: number;
   private unsubscribe = new Subject<void>();
   @ViewChild(LocationComponent, { static: true }) locationComponent: LocationComponent;
   private topBarVisibleClass = "top-bar-visible";

   constructor(
      private topBarService: TopBarService,
      private renderer: Renderer2,
      private systemWebPreferenceService: SystemWebPreferencesService,
      private url: LocationStrategy
   ) {
      this.isVisible = false;
      this.topBarService.topBarIsVisible.pipe(takeUntil(this.unsubscribe)).subscribe((isTopBarVisible) => {
         // Checking to make sure it is only the parent login page that displays the header, so we are filtering out the child routes (veryifyemailaddress/createaccount).
         if (
            this.url.path().includes("/login") &&
            !this.url.path().includes("/verifyemailaddress") &&
            !this.url.path().includes("/createaccount")
         ) {
            this.isLoginPage = true;
            this.isVisible = true;
            isTopBarVisible = true;
         } else {
            this.isLoginPage = false;
            this.isVisible = isTopBarVisible;
         }

         if (isTopBarVisible) {
            if (this.isVisible) {
               this.renderer.addClass(document.body, this.topBarVisibleClass);
            } else {
               this.renderer.removeClass(document.body, this.topBarVisibleClass);
            }
         }
      });
   }

   ngOnInit() {
      if (
         this.url.path().includes("/login") &&
         !this.url.path().includes("/verifyemailaddress") &&
         !this.url.path().includes("/createaccount")
      ) {
         this.locationComponent.authlocationId
            .pipe(
               filter((location) => location != null),
               takeUntil(this.unsubscribe)
            )
            .subscribe((locationid) => {
               this.systemWebPreferenceService
                  .GetSiteHeader(locationid)
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((image) => {
                     if (image) {
                        this.siteHeaderPath = image.DownloadURL;
                     }
                  });

               this.systemWebPreferenceService
                  .GetSiteHeaderSystemWebPreference(locationid)
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((prefs) => {
                     if (prefs) {
                        if (prefs.find((x) => x.Name == "OverrideDefaultHeaderImageOWA")?.Value == "True") {
                           this.overrideDefaultHeader = true;
                        } else {
                           this.overrideDefaultHeader = false;
                        }

                        this.customHeaderColor =
                           prefs.find((x) => x.Name == "OWACustomHeaderBackgroundColor") != null
                              ? (prefs.find((x) => x.Name == "OWACustomHeaderBackgroundColor")?.Value as string)
                              : "#F2F5FA";
                        this.loginHeaderAlignment =
                           prefs.find((x) => x.Name == "OWACustomHeaderImageAlignment") != null
                              ? (prefs.find((x) => x.Name == "OWACustomHeaderImageAlignment")?.Value as string)
                              : "left";
                     }
                  });
               this.topBarService.topBarIsVisible.next(true);
            });
      }
   }
}
