import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { ApiRequestHelperService } from "@lcs/http/api-request-helper.service";
import { ApiService } from "projects/libraries/owa-gateway-sdk/src/lib/core/api.service";
import { Observable, Subject } from "rxjs";

import { SystemWebPreferenceModel } from "../models/system-web-preference.model";
import { FileModel } from "../models/file.model";

@Injectable({ providedIn: "root" })
export class SystemWebPreferencesService implements OnDestroy {
   private endpoint = "systemwebpreferences";
   private unsubscribe = new Subject<void>();

   constructor(
      private apiService: ApiService,
      private httpClient: HttpClient,
      private apiRequestHelperService: ApiRequestHelperService
   ) {}

   ngOnDestroy() {
      this.unsubscribe.next();
   }

   public Get(prefName: string): Observable<SystemWebPreferenceModel> {
      return this.httpClient.get<SystemWebPreferenceModel>(
         this.apiRequestHelperService.url + "systemwebpreferences?name=" + JSON.stringify(prefName),
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }

   public GetCollection(): Observable<Array<SystemWebPreferenceModel>> {
      const additionalParameters = new Array<string>();
      const endpoint = this.endpoint + "/GetAll";
      return this.apiService.getCollection(
         this.apiService.getUrl(
            endpoint,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            additionalParameters
         )
      );
   }

   public GetRequireLocationInfoInURLOWA(): Observable<SystemWebPreferenceModel> {
      return this.httpClient.get<SystemWebPreferenceModel>(
         this.apiRequestHelperService.url + "systemwebpreferences/RequireLocationInfoInURLOWA",
         {
            headers: this.apiRequestHelperService.initialHeaders,
         }
      );
   }

   public GetAccountCreationSystemWebPreference(locationId: number): Observable<SystemWebPreferenceModel> {
      return this.httpClient.get<SystemWebPreferenceModel>(
         this.apiRequestHelperService.url +
            "systemwebpreferences/AccountCreationSystemWebPreference?locationID=" +
            JSON.stringify(locationId),
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   public GetThemeColorSystemWebPreference(locationId: number): Observable<Array<SystemWebPreferenceModel>> {
      return this.httpClient.get<Array<SystemWebPreferenceModel>>(
         this.apiRequestHelperService.url +
            "systemwebpreferences/CustomThemeSystemWebPreferences?locationID=" +
            JSON.stringify(locationId),
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   public GetSiteHeaderSystemWebPreference(locationId: number): Observable<Array<SystemWebPreferenceModel>> {
      return this.httpClient.get<Array<SystemWebPreferenceModel>>(
         this.apiRequestHelperService.url +
            "systemwebpreferences/SiteHeaderSystemWebPreferences?locationID=" +
            JSON.stringify(locationId),
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   public GetSiteHeader(locationId: number): Observable<FileModel> {
      return this.httpClient.get<FileModel>(
         this.apiRequestHelperService.url +
            "systemwebpreferences/SiteHeaderFile?locationID=" +
            JSON.stringify(locationId),
         { headers: this.apiRequestHelperService.initialHeaders }
      );
   }

   static coerceBoolean(preference: SystemWebPreferenceModel): boolean {
      // @ts-ignore ts-migrate(2322) FIXME: Type 'string | boolean' is not assignable to type ... Remove this comment to see the full error message
      return preference && preference.Value && preference.Value.trim().toLowerCase() === "true";
   }
}
