<!-- <div [class.top-bar-visible]="isVisible" class="top-bar-container"> -->

<div
   [hidden]="!isVisible"
   class="top-bar-container"
>
   <owa-rm-location [hidden]="true"></owa-rm-location>
   <ng-container *ngIf="isLoginPage; else defaultTopBar">
      <owa-site-header
         [loginSiteHeaderImagePath]="siteHeaderPath"
         [overrideDefaultHeader]="overrideDefaultHeader"
         [loginCustomHeaderColor]="customHeaderColor"
         [loginHeaderAlignment]="loginHeaderAlignment"
         *ngIf="siteHeaderPath && overrideDefaultHeader"
      ></owa-site-header>
   </ng-container>
   <ng-template #defaultTopBar>
      <owa-site-header *ngIf="isVisible"></owa-site-header>
      <div class="top-bar">
         <owa-menu style="width:100%;"></owa-menu>
      </div>
   </ng-template>
</div>